import React from 'react';
import { ExternalLink, Wrapper } from './Footer.styles';
import Container from '../Container';
import { PRIVACY_POLICY_LINK } from '../../constants';

export const Footer = () => (
  <Wrapper>
    <Container>
        <ExternalLink
          href={PRIVACY_POLICY_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          Polityka Prywatności
        </ExternalLink>
    </Container>
  </Wrapper>
)

export default Footer
