import styled from 'styled-components';

import { MEDIA} from '../../constants';
import {getColor, getSpacing} from '../../theme/helpers';

export const Navigation = styled.nav`
  display: flex;
  max-width: 2500px;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 0 ${getSpacing('xs')};
  background-color: ${getColor('backgroundGray')};

  @media (min-width: ${MEDIA.MED}) {
    padding: 0 ${getSpacing('xl')};
  }
`
