import React from 'react';
import ReactCookieConsent from 'react-cookie-consent';

const CookieConsent = () => (
  <ReactCookieConsent
    location="bottom"
    buttonText="Akceptuję"
    cookieName="cookie-consent"
    style={{
      background: "#F8F8F8",
      display: "flex",
      alignItems: "center",
      color: "#707070",
      boxShadow: "0 -4px 4px -6px #707070",
    }}
    buttonStyle={{
      color: "#ffffff",
      backgroundColor: "#03B982",
      borderRadius: "5px",
      fontSize: "16px",
      padding: "12px",
    }}
  >
    Ta strona wykorzystuje tzw. pliki cookies w celu prawidłowego świadczenia
    usługi i wyświetlania informacji oraz w celu gromadzenia anonimowych danych
    statystycznych. Możesz wyłączyć obsługę cookies przez zmianę ustawień swojej
    przeglądarki internetowej.
  </ReactCookieConsent>
)

export default CookieConsent
