import React from 'react';
import {string} from 'prop-types';

import {Navigation} from './Navbar.styles';
import Logo from '../Logo';

const Navbar = () => {
  return (
    <Navigation>
      <Logo />
    </Navigation>
  )
}

Navbar.propTypes = {
  path: string.isRequired,
}
export default Navbar;
