import React from 'react';

import {LogoWrap, LogoImg} from './Logo.styles';
import {PATTERN_APPLIED_URL} from '../../constants'
import logo from '../../../static/pattern-applied-logo.svg'


const Logo = () => {

  return (
    <LogoWrap href={PATTERN_APPLIED_URL} target="_blank" rel="noopener noreferrer">
      <LogoImg src={logo} alt='Pattern Applied'/>
    </LogoWrap>
  )
}

export default Logo
