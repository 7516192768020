import styled from 'styled-components';

import {MEDIA} from '../../constants';
import {getSpacing} from '../../theme/helpers';

export const LogoWrap = styled.a`
  padding: ${getSpacing('basic')} ${getSpacing('xs')};

  @media (min-width: ${MEDIA.MIN}) {
    padding: ${getSpacing('sm')};
  }
`;

export const LogoImg = styled.img`
  height: 50px;
`;
