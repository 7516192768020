import styled from 'styled-components';

import { getColor, getSpacing, getTypographyColor, getTypographySize} from '../../theme/helpers';

export const Wrapper = styled.div`
  padding: ${getSpacing('xl')} ${getSpacing('sm')};
  background-color: ${getColor('black')};
`;


export const ExternalLink = styled.a`
  width: 100%;
  color: ${getTypographyColor('basicWhite')};
  text-decoration: none;
  font-size: ${getTypographySize('xs')};
  position: relative;

  :after {
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;
    width: 0;
    content: ".";
    color: transparent;
    background-color: ${getColor('basicWhite')};
    height: 1px;
    transition: width 0.25s ease-in;
  }

  :hover {
    ::after {
      width: 100%;
    }
  }
`;
