import React from 'react';
import {ThemeProvider} from 'styled-components';
import {string, node} from 'prop-types';

import Navbar from '../Navbar';
import Footer from '../../components/Footer';
import CookieConsent from '../../components/CookieConsent';
import {theme} from '../../theme/theme';
import GlobalStyle from '../../theme/global-styles';
import { Helmet } from "react-helmet";

const Layout = ({children, path}) => (
  <ThemeProvider theme={theme}>
    <Helmet>
      {/*<!-- Google tag (gtag.js) -->*/}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-ZQ0PT81ECB"></script>
      <script>{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-ZQ0PT81ECB');
      `}</script>
    </Helmet>
    <GlobalStyle />
      <Navbar path={path} />
      {children}
      <Footer/>
    <CookieConsent/>
  </ThemeProvider>
);

Layout.propTypes = {
  children: node.isRequired,
  path: string.isRequired,
}

export default Layout;
